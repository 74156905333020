<div class="push-right">
  <div class="sidebar-mobile">
    <div class="menu-icon-mobile" exceptclick>
      <mat-icon (click)="toggleSidebar()">menu</mat-icon>
    </div>
    <div class="menu-mobile hide-mobile-menu" clickOutside (clickOutside)="clickingOutside()">
      <mat-nav-list>
        <ng-container *ngFor="let child of menuConfigs">
          <ng-container *ngIf="child.title" [ngTemplateOutlet]="menuItemTemplate"
            [ngTemplateOutletContext]="{ item: child }">
          </ng-container>
        </ng-container>
        <!-- <div mat-list-item *ngIf="!isAgent" [routerLink]="['/notifications']" class="item-href"
          [title]="'Notifications' | translate">
          <div [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }" class="icon-container">
            <mat-icon class="notifications">notifications<div *ngIf="hasNew" class="unread"></div></mat-icon>
          </div>
          <div class="title">{{'Notifications' | translate}}</div>
        </div> -->
      </mat-nav-list>
    </div>
  </div>
  <div class="sidebar" [ngStyle]="{'height': 'calc(100% - 15px)'}">
    <mat-nav-list>
      <ng-container *ngFor="let child of menuConfigs">
        <ng-container *ngIf="child.title" [ngTemplateOutlet]="menuItemTemplate"
          [ngTemplateOutletContext]="{ item: child }">
        </ng-container>
      </ng-container>
      <!-- <div mat-list-item *ngIf="!isAgent" [routerLink]="['/notifications']" class="item-href"
        [title]="'Notifications' | translate">
        <div [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }" class="icon-container">
          <mat-icon class="notifications">notifications<div *ngIf="hasNew" class="unread"></div></mat-icon>
        </div>
        <div class="title">{{'Notifications' | translate}}</div>
      </div> -->
    </mat-nav-list>
  </div>
</div>

<ng-template #menuItemTemplate let-item="item" let-parentItem="parentItem">
  <ng-container *ngIf="!item.submenu && !item.permission" [ngTemplateOutlet]="menuItemInnerTemplate"
    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  <ng-template *ngIf="!item.submenu && item.permission" [ngxPermissionsOnly]="item.permission">
    <ng-container [ngTemplateOutlet]="menuItemInnerTemplate"
      [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  </ng-template>
  <ng-container *ngIf="item.submenu && !item.permission" [ngTemplateOutlet]="menuItemInnerTemplateSub"
    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  <ng-template *ngIf="item.submenu && item.permission" [ngxPermissionsOnly]="item.permission">
    <ng-container [ngTemplateOutlet]="menuItemInnerTemplateSub"
      [ngTemplateOutletContext]="{ item: item, parentItem: parentItem  }"></ng-container>
  </ng-template>
</ng-template>

<ng-template #menuItemInnerTemplate let-item="item" let-parentItem="parentItem">
  <a *ngIf="item.target" mat-list-item href="{{item.page}}" target="{{item.target}}" class="item-href"
    [title]="item.title">
    <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
    <div class="title">{{ item.title | translate }}</div>
  </a>
  <div *ngIf="!item.target" mat-list-item [routerLink]="[item.page]" class="item-href" [title]="item.title">
    <div [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }" class="icon-container">
      <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
    </div>
    <div class="title">{{ item.title | translate }}</div>
  </div>
</ng-template>

<ng-template #menuItemInnerTemplateSub let-item="item" let-parentItem="parentItem">
  <div class="nested-menu">
    <a mat-list-item (click)="addExpandClass(item.target)">
      <mat-icon matListItemIcon class="sidenav-icon">{{item.icon}}</mat-icon> {{ item.title | translate }}
    </a>
    <ul class="nested submenu" [class.expand]="showMenu === item.target">
      <li *ngFor="let child of item.submenu">
        <ng-container *ngIf="child.title" [ngTemplateOutlet]="menuItemTemplate"
          [ngTemplateOutletContext]="{ item: child }"></ng-container>
      </li>
    </ul>
  </div>
</ng-template>