import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestService, MenuConfigService } from '../../../shared/services';
import * as objectPath from 'object-path';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public menuConfigs: any[] = [];
  public currentRoute: string = '';
  public topNavHeight: any = undefined;
  public hasNew: boolean = false;
  public isAgent: boolean = false;

  constructor(private requestService: RequestService, public router: Router, private menuConfigService: MenuConfigService, private translate: TranslateService, private firebaseHandler: FirebaseHandlersService, private activatedRoute: ActivatedRoute, public dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef, private adAuthService: MsalService, private notificationService: NotificationService) {
    this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');

    router.events.subscribe((data) => {
      if (data instanceof NavigationEnd ||
        data instanceof NavigationError) {
        this.currentRoute = activatedRoute.snapshot['_routerState'].url;
        // console.log(`[${this.currentRoute}]`)
        this.clickingOutside();
      }
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.menuConfigService.onConfigUpdated$.subscribe((data) => {
        if (data) {
          this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
        }
      })
    );

    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAgent = this.requestService.isUserAgent();
        }
      })
    );

    this.subscriptions.push(this.notificationService.showNotificationUnRead.subscribe((show: any) => {
      if (this.currentRoute !== '/notifications' || !show)
        this.hasNew = show;
    }));
  }

  ngAfterViewInit(): void {
    // this.calculateHeight();
    // setTimeout(() => this.calculateHeight(), 1000);
  }

  private calculateHeight() {
    // console.log(document.getElementById('top-nav'))
    if (document.getElementById('top-nav')) {
      // let height = document.getElementById('top-nav').clientHeight + 10;
      // console.log(height)
      // this.topNavHeight = `calc(100% - ${height}px)`;
      this.topNavHeight = 'calc(100% - 20px)';
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  public logout() {
    this.adAuthService.logout();
    this.firebaseHandler.logout();
  }

  clickingOutside() {
    const dom: any = document.querySelector('.menu-mobile');
    dom.classList.add('hide-mobile-menu');
  }

  toggleSidebar() {
    const dom: any = document.querySelector('.menu-mobile');
    dom.classList.toggle('hide-mobile-menu');
  }
}
