import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { ModalPasswordDialogComponent } from '../../../shared/components/custom-password-dialog/custom-password-dialog.component';

import { ModalDialogComponent } from '../custom-dialog/custom-dialog.component';
import { ModalCropDialogComponent } from '../custom-crop-dialog/custom-crop-dialog.component';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
import { NotificationService } from '../../services/notification.service';

@Component({
	selector: 'app-user-view-dialog-modal',
	templateUrl: './custom-user-view-dialog.component.html',
	styleUrls: ['./custom-user-view-dialog.component.scss']
})
export class ModalUserViewDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public errorMessage: string = '';
	public organization: any = undefined;
	public orgId = undefined;
	public scopes: string[] = [];
	public loading: boolean = false;
	public dataType: any = 'user'
	public hasFormErrors: boolean = false;
	public pictureLink: string = 'assets/images/profile.png';
	public fieldsDetails: any = undefined;
	public permission: any[] = [];
	public metaFieldTabsSetting = [];
	public tableDataTemp: any = undefined;
	public DND: boolean = false;
	public isProfile: boolean = false;
	public showChangePassword: boolean = false;
	public normalDisplay: string[] = ['string', 'integer', 'phone', 'number', 'email', 'textarea'];
	public isDnd: boolean = false;

	constructor(
		public dialog: MatDialog, private translate: TranslateService,
		private requestService: RequestService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalUserViewDialogComponent>,
		private loaderService: LoaderService,
		@Inject(MAT_DIALOG_DATA) public data: any, private firebaseHandler: FirebaseHandlersService, private notificationService: NotificationService) {
		// console.log('UserViewDialogData', data);
		if (data && data.profile) {
			this.isProfile = data.profile;
		}
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.pageOrganization.subscribe((data) => {
				if (data) {
					this.organization = data;
					this.orgId = data._id;
				}
			})
		);

		this.subscriptions.push(this.notificationService.dnd.subscribe(async (isDnd) => {
			this.isDnd = isDnd;
		}));
	}
	ngOnDestroy() {
		this.subscriptions.forEach((s) => s.unsubscribe());
	}

	convertArrayToObject(arr: any[]) {
		let obj = {};
		for (let a of arr) {
			obj[a._id] = a;
		}
		return obj;
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}

	public viewTranscript(transcript: any) {
		window.open(transcript.fileLink, '_blank');
	}
	changeDND(event) {
		console.log(event.checked);
		localStorage.setItem("DND", JSON.stringify(event.checked))
	}

	saveDnd() {
		this.loaderService.display(true);
		this.requestService.postRequest('user', 'update', {
			userId: this.data.data._id,
			dnd: this.isDnd
		}, async (data, error) => {
			if (data) {
				if (this.isDnd) {
					let topics = this.firebaseHandler.subscribedTopics.filter(i => {
						return !i.includes('high-');
					});
					if (topics?.length) {
						await this.firebaseHandler.unsubscribeTopic(topics, this.data.data._id);
					}

					await this.firebaseHandler.unregisterDevice(this.data.data._id, this.firebaseHandler.token, () => {
						this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
					}).catch((e) => {
						this.layoutUtilsService.showNotification('Error: ' + e, this.translate.instant('Dismiss'));
					}).finally(() => this.loaderService.display(false));
				}
				else {
					let userRole = this.requestService.getUserRole();
					let topics = [`high-${userRole}`, userRole];

					if (this.requestService.currentUser?.codesAndStatuses) {
						this.requestService.currentUser.codesAndStatuses.AccessPoint.forEach(point => {
							if (point && point != '{}') {
								topics.push(`high-${point}`);
								topics.push(point);
							}
						});

						this.requestService.currentUser.codesAndStatuses.AgentCode.forEach(code => {
							if (code && code != '{}') {
								topics.push(`high-${code}`);
								topics.push(code);
							}
						});

						this.requestService.currentUser.codesAndStatuses.Carrier.forEach(carrier => {
							if (carrier && carrier != '{}') {
								topics.push(`high-${carrier}`);
								topics.push(carrier);
							}
						});
					}

					this.firebaseHandler.subscribeTopic(topics, this.requestService.currentUser._id);
					this.firebaseHandler.registerToken(this.data.data._id).then(() => {
						this.loaderService.display(false);
						this.layoutUtilsService.showNotification(this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
					});
				}
			}
			else if (error) {
				this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
				this.loaderService.display(false);
			}
			localStorage.setItem('dnd', this.isDnd + '');
			this.notificationService.dnd.next(this.isDnd);
		});
	}
}
