import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleMapsModule } from "@angular/google-maps";
// import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { GlobalService } from './services/global.service';

import {
    AlertActionEntityDialogComponent, SidebarComponent, TopnavComponent, PageHeaderComponent, SnackBarComponent, ActionNotificationComponent, ConfirmInputEntityDialogComponent, AlertComponent,
    UpdateEntityDialogComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent, AlertEntityDialogComponent, LoadingScreenComponent, ModalViewDialogComponent, EnumViewComponent, ErrorEntityDialogComponent, ConfirmEntityDialogComponent, ModalIframeDialogComponent, ModalUserDialogComponent, ModalDialogComponent, ModalPasswordDialogComponent, ModalUserViewDialogComponent, CustomSelectComponent, CustomTagsComponent, ModalIframeHeaderDialogComponent, CustomModalViewEventDialogComponent, CustomStartEndDateTimeComponent, ModalSchedulerExportDialogComponent, ModalUploaderDialogComponent, GoogleMapsComponent, GoogleMapsDialogComponent, CustomVideoComponent, ModalLanguageDialogComponent, ModalCollectionDialogComponent,
    ModalImageViewDialogComponent
} from './components';
import { ModalFieldCropDialogComponent } from './components';

import { ModalGalleryDialogComponent } from './components';
import { ModalEmailDialogComponent, ModalVideoDialogComponent, ModalPagesDialogComponent, ModalEventMediaDialogComponent, ModalFormMediaDialogComponent, ModalEventDocumentDialogComponent, ModalCalenderDialogComponent, CustomSelectAutocompleteComponent, CustomSelectCreateAutocompleteComponent } from './components';

import { CustomMetadataTableComponent, CustomMultipleSelectComponent, CustomMultipleSelectTreeComponent, CustomSelectCreateAutocompleteDialogComponent, IframeUrlComponent, ModalLayoutDialogComponent, SliderComponent, CustomSelectDialogComponent, ModalCropDialogComponent, MarginViewComponent, CustomDateTimeComponent } from './components';
import { ClickOutSideDirective, OrdinalDatePipe, FocusOnLoadDirective, HideOnWidthDirective } from './directives';
import { ConfirmSelectEntityDialogComponent } from './components';
// import { CkeditorBlockComponent, TextBlockComponent, LinkDescriptionBlockComponent, PictureBlockComponent, DisqusBlockComponent, SocialFeedBlockComponent, CalendarBlockComponent, PatientsBlockComponent, InquiryBlockComponent, SurveyBlockComponent, QuestionnaireBlockComponent, QuestionnaireSubOptionComponent, DescriptionSubOptionComponent, StartWrapperBlockComponent, AddSpaceComponent, FormTitleBlockComponent, QuestionsBlockComponent, ConfirmationBlockComponent, PasswordBlockComponent, NextBlockComponent, FormPhotoComponent, FormDocumentComponent, PainLevelComponent, DrawToolBlockComponent, PhysicianBlockComponent, EndWrapperBlockComponent, FillBlockComponent, NotesBlockComponent, ButtonsBlockComponent, ContactUsBlockComponent, PlacefullBlockComponent, AddToCartBlockComponent, CartBlockComponent, BlanksFormBlockComponent, ExclusiveUrlBlockComponent, FileUploadBlockComponent, PushpayBlockComponent, ThreedCartBlockComponent, BlogsBlockComponent, ChatBlockComponent, AccountBlockComponent, ProfileBlockComponent, TileSelectDialogComponent, TileDataComponent, TilesListComponent, CustomTileViewComponent, ConfirmCkeditorEntityDialogComponent, WidgetComponent, ModalPreviewDialogComponent } from './modules/forms-libararies';

import { Utils } from './helpers/utils';

import { ImageCropperModule } from 'ngx-image-cropper';
import { FileModalDialogComponent } from './components/custom-file-upload-dialog/custom-file-upload-dialog.component';
import { SimpleAlertEntityDialogComponent } from './components/modals/simple-entity-dialog/simple-entity-dialog.component';
import { MyFlexLayoutDirective } from './directives/my-flex-layout.directive';
// import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        GoogleMapsModule,
        FormsModule,
        ReactiveFormsModule,
        ImageCropperModule,
        // FlexLayoutModule.withConfig({ addFlexToParent: false })
        // DeviceDetectorModule
    ],
    providers: [
        // GlobalService
        Utils
    ],
    declarations: [
        SidebarComponent,
        AlertActionEntityDialogComponent,
        TopnavComponent,
        PageHeaderComponent,
        SnackBarComponent,
        EnumViewComponent,
        ActionNotificationComponent,
        ConfirmInputEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        UpdateEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        SimpleAlertEntityDialogComponent,
        ModalIframeDialogComponent,
        ModalUserDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        LoadingScreenComponent,
        ModalDialogComponent,
        FileModalDialogComponent,
        ModalPasswordDialogComponent,
        ModalUserViewDialogComponent,
        CustomSelectComponent,
        // SpeakerCameraComponent,
        CustomTagsComponent,
        // TileWidgetComponent,
        ModalIframeHeaderDialogComponent,
        ModalGalleryDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomMetadataTableComponent,
        ModalEmailDialogComponent,
        ModalVideoDialogComponent,
        ModalPagesDialogComponent,
        ModalEventMediaDialogComponent,
        ModalFormMediaDialogComponent,
        ModalEventDocumentDialogComponent,
        ModalCalenderDialogComponent,
        CustomMultipleSelectComponent,
        CustomMultipleSelectTreeComponent,
        CustomSelectCreateAutocompleteDialogComponent,
        IframeUrlComponent,
        ModalLayoutDialogComponent,
        CustomSelectDialogComponent,
        ClickOutSideDirective,
        FocusOnLoadDirective,
        OrdinalDatePipe,
        SliderComponent,
        MarginViewComponent,
        CustomDateTimeComponent,
        ModalCropDialogComponent,
        ModalFieldCropDialogComponent,
        CustomModalViewEventDialogComponent,
        CustomStartEndDateTimeComponent,
        ModalSchedulerExportDialogComponent,
        ModalUploaderDialogComponent,
        GoogleMapsComponent,
        GoogleMapsDialogComponent,
        CustomVideoComponent,
        ModalLanguageDialogComponent,
        ModalCollectionDialogComponent,
        ModalImageViewDialogComponent,
        MyFlexLayoutDirective,
        HideOnWidthDirective,
    ],
    exports: [
        SidebarComponent,
        AlertActionEntityDialogComponent,
        TopnavComponent,
        PageHeaderComponent,
        ActionNotificationComponent,
        ConfirmInputEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        UpdateEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        SimpleAlertEntityDialogComponent,
        ModalIframeDialogComponent,
        ModalUserDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        EnumViewComponent,
        LoadingScreenComponent,
        ModalDialogComponent,
        FileModalDialogComponent,
        ModalPasswordDialogComponent,
        ModalUserViewDialogComponent,
        CustomSelectComponent,
        // SpeakerCameraComponent,
        CustomTagsComponent,
        // TileWidgetComponent,
        ModalIframeHeaderDialogComponent,
        ModalGalleryDialogComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomMetadataTableComponent,
        ModalEmailDialogComponent,
        ModalVideoDialogComponent,
        ModalPagesDialogComponent,
        ModalEventMediaDialogComponent,
        ModalFormMediaDialogComponent,
        ModalEventDocumentDialogComponent,
        ModalCalenderDialogComponent,
        CustomMultipleSelectComponent,
        CustomMultipleSelectTreeComponent,
        CustomSelectCreateAutocompleteDialogComponent,
        IframeUrlComponent,
        ModalLayoutDialogComponent,
        CustomSelectDialogComponent,
        ClickOutSideDirective,
        FocusOnLoadDirective,
        OrdinalDatePipe,
        SliderComponent,
        MarginViewComponent,
        CustomDateTimeComponent,
        ModalCropDialogComponent,
        ModalFieldCropDialogComponent,
        // EmojiFormComponent,
        // EmojiSliderFieldComponent,
        // FireworksBackgroundComponent,
        CustomModalViewEventDialogComponent,
        CustomStartEndDateTimeComponent,
        ModalSchedulerExportDialogComponent,
        ModalUploaderDialogComponent,
        GoogleMapsComponent,
        GoogleMapsDialogComponent,
        ReactiveFormsModule,
        CustomVideoComponent,
        ModalLanguageDialogComponent,
        ModalCollectionDialogComponent,
        ModalImageViewDialogComponent,
        MyFlexLayoutDirective,
        HideOnWidthDirective
    ]
})
export class LayoutComponentModule { }
